import 'bootstrap-sass';
import 'flexslider';
import '@zeitiger/elevatezoom';
import 'slick-carousel';
// We have to declare this as a window (global variable) to be able to use it outside of the scope of the js files (it's used in the footer)
import algoliasearch from 'algoliasearch';
window.algoliasearch = algoliasearch;
// We have to declare this as a window (global variable) to be able to use it outside of the scope of the js files (it's used in the footer)
import autocomplete from 'autocomplete.js';
window.autocomplete = autocomplete;
